import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import Landing from './pages/Landing.vue';
import Exam from './pages/components/Exam.vue';
import PreExam from './pages/components/PreExam.vue';
import ResultSummary from './pages/components/ExamResultSummary.vue';
import ResultDetail from './pages/components/ExamResultDetails.vue';
import ExamResult from './pages/components/ExamResult.vue';
import ExamChoose from './pages/components/ExamChoose.vue';
import ExamChooseUben from './pages/components/ExamChooseUben.vue';
import LesenChoose from './pages/components/LesenChoose.vue';
import Lesen from './pages/components/Lesen.vue';
import Test from './pages/components/Test.vue';
import Login from './pages/Login.vue';
import Profile from './pages/Profile.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import UserList from './pages/components/Admin/User/List.vue';
import NewUser from './pages/components/Admin/User/New.vue';
import EditUser from './pages/components/Admin/User/Edit.vue';
import QuestionList from './pages/components/Admin/Question/List.vue';
import NewQuestion from './pages/components/Admin/Question/New.vue';
import EditQuestion from './pages/components/Admin/Question/Edit.vue';
import HomePageEdit from './pages/components/Admin/HomePage/Edit.vue';

import { store } from './store/store'

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

export default new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    // {
    //   path: '/test',
    //   name: 'test',
    //   components: { default: Test, header: MainNavbar },
    //   props: {
    //     header: { colorOnScroll: 400 },
    //     footer: { backgroundColor: 'black' }
    //   }
    // },
    {
      path: '/',
      name: 'index',
      components: { default: Index, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/exam',
      name: 'exam',
      components: { default: Exam, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/preexam',
      name: 'preexam',
      components: { default: PreExam, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/lesenChoose',
      name: 'lesenChoose',
      components: { default: LesenChoose, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/lesen',
      name: 'lesen',
      components: { default: Lesen, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/examChoose',
      name: 'examChoose',
      components: { default: ExamChoose, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/examChooseUben',
      name: 'examChooseUben',
      components: { default: ExamChooseUben, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/result',
      name: 'result',
      components: { default: ExamResult, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/resultSummary',
      name: 'resultSummary',
      components: { default: ResultSummary, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/resultDetail',
      name: 'resultDetail',
      components: { default: ResultDetail, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    // {
    //   path: '/landing',
    //   name: 'landing',
    //   components: { default: Landing, header: MainNavbar, footer: MainFooter },
    //   props: {
    //     header: { colorOnScroll: 400 },
    //     footer: { backgroundColor: 'black' }
    //   }
    // },
    {
      path: '/userList',
      name: 'userList',
      components: { default: UserList, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/newUser',
      name: 'newUser',
      components: { default: NewUser, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/editUser',
      name: 'editUser',
      components: { default: EditUser, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/questionList',
      name: 'questionList',
      components: { default: QuestionList, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/newQuestion',
      name: 'newQuestion',
      components: { default: NewQuestion, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/editQuestion',
      name: 'editQuestion',
      components: { default: EditQuestion, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 }
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/homePageEdit',
      name: 'homePageEdit',
      components: { default: HomePageEdit, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      beforeEnter: ifAuthenticated
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
