<template>
    <div class="page-header clear-filter">
      <div class="container">
        <div class="card">
          <div
            class="
              tab-content tab-space
              text-left
              tab-content-padding
              text-center
            "
          >
            <div>
                <input type="file" @change="handleFileUpload" accept="image/*" />
                <canvas ref="canvas" style="display: none"></canvas>
            </div> 
            <div>
                <button v-if="resizedImage" @click="uploadImage">Resmi Yükle</button>
                <img v-if="previewUrl" :src="previewUrl" alt="Önizleme" />
            </div>  
            asdasds
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import 'vue-select/dist/vue-select.css';
  export default {
    name:'imageUpload',
        data(){
            return{
                resizedImage: null,
                previewUrl: null,
            }
        },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.resizeImage(file);
            }
        },resizeImage(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext("2d");

                // Yeni boyutlar (örnek: 300x300)
                const MAX_HEIGHT = 600;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    this.resizedImage = new File([blob], "resized.jpg", { type: "image/jpeg" });

                    // Önizleme için URL oluştur
                    this.previewUrl = URL.createObjectURL(this.resizedImage);
                }, "image/jpeg", 0.9);
                };
            };
        },     
        async uploadImage() {
            if (!this.resizedImage) return;

            const formData = new FormData();
            formData.append("image", this.resizedImage);

            try {
                const response = await axios.post("http://localhost:3000/upload", formData, {
                headers: { "Content-Type": "multipart/form-data" },
                });

                alert(response.data.message);
            } catch (error) {
                
            }
        },   
    },
    watch: {
      showLoginButton: function (newValue, oldValue) {
        this.currentUser = this.$store.getters.getUser;
        this.loginButton = newValue;
      },
      selectedCategory: function(newVal, oldVal) {
        this.loadQuestions();
      },
      selectedSubCategory: function(newVal, oldVal) {
        this.loadQuestions();
      }
    },
  };
  </script>
  
  <style >
  
  </style>